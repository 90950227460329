/*******************************************************************************
 * Custom modifications to the theme
 ******************************************************************************/
// Front page

table.table-sm th {
    width: 15%;
}

.profile .address {
    font-family: Roboto, sans-serif;
    font-size:.95rem;
    padding-top: 1em;
}

// Blog Posts
.post-content img, .gif {
    width:35%;
    float: right;
    margin-left: 1rem;
}

.gif iframe {
    width: 100%;
    height: 80%;
}

.gif p {
    text-align: center;
}