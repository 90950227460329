/*******************************************************************************
 * Variables used throughout the theme.
 * To adjust anything, simply edit the variables below and rebuild the theme.
 ******************************************************************************/

// Dimensions

$max-content-width: 800px;

// Colors
$red-color:           #FF3636 !default;
$red-color-dark:      #B71C1C !default;
$orange-color:        #F29105 !default;
$blue-color:          #0076df !default;
$blue-color-dark:     #00369f !default;
$cyan-color:          #2698BA !default;
$green-color:         #00ab37 !default;
$green-color-lime:    #B7D12A !default;
$green-color-dark:    #009f06 !default;
$green-color-light:   #ddffdd !default;
$green-color-bright:  #11D68B !default;
$purple-color:        #B509AC !default;
$pink-color:          #f92080 !default;
$pink-color-light:    #ffdddd !default;
$yellow-color:        #efcc00 !default;
$sea-blue:            #3D6075; //https://www.design-seeds.com/tag/blue-sea/
$sea-green:           #548D96;
$rose:                #c24b5e;

$grey-color:          #828282 !default;
$grey-color-light:    lighten($grey-color, 40%);
$grey-color-dark:     darken($grey-color, 25%);

/* Set theme colors *************************/
$theme-color:         $rose;

$link-color:          $theme-color;
$text-color:          #111 !default;
